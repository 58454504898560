// filters that are common across the application

import moment from 'moment';

export default {


    //capitalize the first letter of a string
    capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    },

    //format date using moment js
    readable_date: function (value, inputformat) {
        return moment(value, inputformat).format("ll")
    },

    standard_date: function (value, inputformat) {
        return moment(value, inputformat).format("YYYY-MM-DD")
    },

    humanize(value) {
        let v = 0;
        if (value >= 1000000000 || value <= -1000000000) {
            v = value / 1e9;
            return v.toFixed(2) + 'B';
        } else if (value >= 1000000 || value <= -1000000) {
            v = value / 1e6;
            return v.toFixed(2) + 'M';
        } else if (value >= 1000 || value <= -1000) {
            v = value / 1e3;
            return v.toFixed(2) + 'K';
        }
        return value;
    },

    getClosestRoundedNumber(num) {
        let goal = num;

        let numbers = [
            1, 2, 4, 5, 10, 20, 25, 30, 35, 40, 45, 50, 100, 200, 250, 300, 350,
            500, 750, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 10000,
        ];

        if (num < 0.01) goal = num * 1000;
        else if (num < 0.1) goal = num * 100;
        else if (num < 1) goal = num * 10;

        if (num > 1000000) goal = num / 1000;
        else if (num > 100000) goal = num / 100;
        else if (num > 10000) goal = num / 10;

        let output = 10 ** Math.ceil(Math.log10(goal));

        if (goal < 10000) {
            output = numbers.reduce((prev, curr) =>
                Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
            );
        }

        if (num < 0.01) output = output / 1000;
        else if (num < 0.1) output = output / 100;
        else if (num < 1) output = output / 10;

        if (num > 1000000) output = output * 1000;
        else if (num > 100000) output = output * 100;
        else if (num > 10000) output = output * 10;

        return output;
    },

    //get the min from negative and max from positive set of values
    getYMinMaxSigned(data, x = ["date"]) {
        let ymin = 0;
        let ymax = 0;

        let ydata = JSON.parse(JSON.stringify(data));
        ydata.forEach((el) => {
            for (let key in el) {
                if (x.includes(key)) {
                    delete el[key];
                }
            }
        });
        console.log("ydata", ydata);

        let plusdata = ydata.map((el) => {
            let values = Object.values(el);
            let total = values.reduce((sum, val) => {
                if (typeof val == "number" && val > 0) return sum + val;
                else return sum;
            }, 0);
            return total;
        });
        let minusdata = ydata.map((el) => {
            let values = Object.values(el);
            let total = values.reduce((sum, val) => {
                if (typeof val == "number" && val < 0) return sum + val;
                else return sum;
            }, 0);
            return total;
        });
        if (plusdata.length > 0 && minusdata.length > 0) {
            ymin = Math.min(...minusdata);
            ymax = Math.max(...plusdata);
        } else {
            if (plusdata.length > 0) {
                ymax = Math.max(...plusdata);
                ymin = Math.min(...plusdata);
            } else {
                ymax = Math.max(...minusdata);
                ymin = Math.min(...minusdata);
            }
        }

        console.log("ymin", ymin);
        console.log("ymax", ymax);
        return { ymin, ymax };
    },

    getYMinMax(data, x = ["date"]) {
        let ymin = 0;
        let ymax = 0;
        let totalData = [];
        let ydata = JSON.parse(JSON.stringify(data));
        ydata.forEach((el) => {
            for (let key in el) {
                if (x.includes(key)) {
                    delete el[key];
                }
            }
        })
        ydata.forEach((el) => {
            let values = Object.values(el);
            totalData.push(...values);
        });
        ymin = Math.min(...totalData);
        ymax = Math.max(...totalData);
        return { ymin, ymax };
    }
}