<template>
    <BasePlot :id="id" :plot="plot" v-if="plot" class="w-100 h-100"></BasePlot>
</template>

<script>
import BasePlot from "@/components/assets/BasePlot.vue";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import * as topojson from "topojson";

export default {
    name: 'AssetsChart',
    props: ["id", "dataset"],
    components: {
        BasePlot
    },

    data() {
        return {
            plot: null,
            formatYear: d3.utcFormat("%Y"),
            formatMonth: d3.utcFormat("%Y-%b"),
            formatHour: d3.utcFormat("%H"),
            parseDate: d3.utcParse("%Y-%m-%d"),
            parseDateTime: d3.utcParse("%Y-%m-%d %H:%M:%S"),
            formatNumber: d3.format("~s"),
        }
    },


    async mounted() {
        console.log("AssetsChart mounted");



        this.plot = await this.plotLocation(this.dataset);
        console.log(this.plot);
    },


    methods: {

        async getGeoData() {
            let us = await d3.json("https://unpkg.com/us-atlas@3/counties-10m.json");
            console.log("us", us);
            let nation = topojson.feature(us, us.objects.nation)
            // let usStateCodes = import('https://cdn.skypack.dev/us-state-codes@1.1.2?min').then(d => d.default)
            // let stateShapes = topojson.feature(us, us.objects.states)
            let statesmesh = topojson.mesh(us, us.objects.states, (a, b) => a !== b)
            console.log("nation", nation);
            console.log("statesmesh", statesmesh);
            return { nation, statesmesh }
        },

        async plotLocation(data) {
            console.log("data =", data)
            let { nation, statesmesh } = await this.getGeoData();
            console.log("plot location data: ", data);
            const plot = Plot.plot({

                projection: "albers",
                marks: [
                    Plot.geo(nation),
                    Plot.geo(statesmesh, { strokeOpacity: 0.2, }),
                    Plot.dot(data, { x: "longitude", y: "latitude", r: 10, fill: "red", stroke: "black", strokeWidth: 1 })
                ]
            })
            return plot;
        }
    }
}
</script>