<template>
  <TimeseriesChart :chartoptions="chartOptions" :chartdata="chartData" v-if="chartOptions && chartOptions.timeseries"
    :key="refresh_timeseries">
  </TimeseriesChart>
  <CategoryChart :chartoptions="chartOptions" :chartdata="chartData" v-else :key="refresh_category"></CategoryChart>
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";
import CategoryChart from "./CategoryChart.vue";
import TimeseriesChart from "./TimeseriesChart.vue";

export default {
  name: "MultiChart",
  components: {
    CategoryChart, TimeseriesChart
  },
  props: ["txn_slug"],

  data() {
    return {
      chartData: null,
      chartOptions: null,
      chartMetaData: {},
      charttypes: {
        linechrt: "line",
        barchart: "bar",
        groupbar: "bar",
        stackbar: "bar",
        histogram: "bar",
        radrplot: "line",
      },
      refresh_category: 1,
      refresh_timeseries: 1,

    };
  },
  computed: {
    ...mapGetters(["serverdata"]),

  },

  async mounted() {
    console.log("MultiChart mounted", this.serverdata);
    this.chartData = null;
    this.chartOptions = null;
    if (this.serverdata && this.serverdata.transaction_slug) {
      try {
        this.showLoader();
        let response = await this.loadAPI(this.serverdata);
        console.log("response from api: ", response);
        if (response.data && response.data.data) {
          let chart = this.setChart(response.data);
          this.chartOptions = chart.chartoptions;
          this.chartData = chart.chartdata;
        } else {
          this.chartOptions = {}
          this.chartOptions.chart_image = this.serverdata.chart_image
        }
      } finally {
        this.stopLoader();
      }
    } else {
      this.chartOptions = {}
      this.chartOptions.chart_image = this.serverdata.chart_image
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    ...mapMutations(["showLoader", "stopLoader", "systemError"]),

    onResize() {
      this.refresh_category = !this.refresh_category;
      this.refresh_timeseries = !this.refresh_timeseries;
    },

    async loadAPI(data) {
      let url =
        "/api/v1/stack/transaction/" +
        data.transaction_slug +
        "/multichart/";
      let response = await this.$axios.post(url, data);
      return response;
    },

    setChart(data) {
      console.log("set options and data for the chart", data);

      //set the toolbar buttons for aggregation, charttype and sort_order
      this.setToolbarOld(data.meta.aggregation, data.meta.charttype, data.meta.sort_order, data.meta.units)

      let chartoptions = {}
      let chartdata = []

      //set data
      chartdata = data.data.map(el => {
        for (let key in el) {
          if (typeof (el[key]) == 'number' && !Number.isInteger(el["key"])) {
            el[key] = parseFloat(el[key].toFixed(2))
          }
        }
        return el;
      });

      //set options
      chartoptions = {
        uuid: "xyz123",
        x: "date",
        showX: true,
        value: [],
        names: [],
        types: null,
        type: null,
        groups: [],
        ylabels: [],
        aggregation: null,
        chart_image: null,
      };
      chartoptions.uuid = data.meta.slug;
      chartoptions.x = data.meta.x
      chartoptions.showX = true
      chartoptions.value =
        Object.keys(chartdata[0]).filter(
          (el) => el != data.meta.x
        );
      chartoptions.names =
        chartoptions.value.forEach((el) => {
          return chartoptions.names[el] = this.$filters.capitalize(el);
        });
      chartoptions.types = null;

      chartoptions.type = this.charttypes[data.meta.charttype];
      if (chartoptions.type == "line") {
        chartoptions.type = "step"
      }
      chartoptions.chart_image = this.serverdata.chart_image
      chartoptions.aggregation = data.meta.aggregation
      chartoptions.units = data.meta.units
      chartoptions.units_long_name = data.meta.units_long_name
      chartoptions["ytext"] = `${this.$filters.capitalize(
        data.meta.coltype
      )}-${this.$filters.capitalize(data.meta.subtype)}-${this.$filters.capitalize(
        data.meta.column
      )} (${chartoptions.units_long_name})`;

      if (data.regions && Object.keys(data.regions).length > 0) {
        chartoptions["regions"] = data.regions;
      } else {
        chartoptions["regions"] = [];
      }

      chartoptions["grouped"] = data.meta["grouped"];
      if (data.meta["charttype"] == "barchart") {
        let groupdata = Object.keys(chartdata[0]).filter((el) => el != chartoptions.x);
        if (data.meta["grouped"]) {
          chartoptions["groups"] = this.createGroups(groupdata);
        } else {
          chartoptions["groups"] = groupdata;
        }
      } else {
        chartoptions["groups"] = [];
      }

      let result = {};
      if (chartoptions.groups && chartoptions.groups.length > 1 && !chartoptions.grouped) {
        result = this.$filters.getYMinMaxSigned(chartdata, [chartoptions.x]);
      } else {
        result = this.$filters.getYMinMax(chartdata, [chartoptions.x]);
      }
      console.log("ymax min, result", result);
      chartoptions.ylabels = this.setYlabels(result.ymin, result.ymax)

      if (data.meta.x == "date" && !data.meta.sort_order) {
        chartoptions.timeseries = true;
        chartoptions.xFormat = "%Y-%m-%d";
      } else {
        chartoptions.timeseries = false;
      }

      console.log("chart options set: ", chartoptions);
      console.log("chartData = ", chartdata);

      return { chartoptions, chartdata };
    },

    createGroups(data) {
      let b = {};
      data.forEach(el => {
        let s = el.split("::");
        if (!b[s[0]]) b[s[0]] = [];
        b[s[0]].push(el);
      });
      console.log("create groups = ", data)
      return Object.values(b);
    },

    setToolbarOld(aggregation, charttype, sort_order, units) {
      console.log("setToolbarOld", aggregation, charttype, sort_order);
      let agg_el = document.getElementById("old_aggregation");
      let chart_el = document.getElementById("old_charttype");
      let sort_el = document.getElementById("old_sort_order");
      let units_el = document.getElementById("old_units");
      console.log("agg_el = ", agg_el);
      if (agg_el) {
        agg_el.value = aggregation;
        agg_el.onchange();
      }
      if (chart_el) {
        chart_el.value = charttype;
        chart_el.onchange();
      }
      if (sort_el) {
        sort_el.value = sort_order ? sort_order : "";
        sort_el.onchange();
        if (sort_el.value === "") {
          document.getElementById("sort_order_none").checked = true;
        }
      }
      if (units_el) {
        console.log("units_el = ", units_el, units);
        units_el.value = units;
        units_el.onchange();
      }

    },


    setYlabels(ymin, ymax) {
      let range = ymax - ymin;
      console.log("range = ", range);
      if (range == 0) {
        return [ymin, ymax];
      }
      let tic_range = range / 5;
      console.log("tic_range = ", tic_range);
      let rounded_tick_range = this.$filters.getClosestRoundedNumber(tic_range);
      console.log("rouned_tick_range = ", rounded_tick_range);

      let new_ymin = rounded_tick_range * Math.floor(ymin / rounded_tick_range);
      let new_ymax =
        rounded_tick_range * Math.round(1 + ymax / rounded_tick_range);
      console.log("new_ymin, new_ymax = ", new_ymin, new_ymax);

      let ylabels = [];
      let ylabel = new_ymin;
      while (ylabel <= new_ymax) {
        ylabels.push(
          parseFloat(ylabel.toFixed(2))
        );
        ylabel += rounded_tick_range;
      }

      console.log("ylabels = ", ylabels);
      return ylabels;
    },


  },
};
</script>
  
<style></style>