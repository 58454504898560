<template>
    <div
      id="form-error"
      class="alert alert-danger alert-dismissible fade show m-0"
      role="alert"
      v-if="show"
    >
      There was an error while processing your request. Please try again.
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="$emit('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: ["show"],
  };
  </script>
  
  <style>
  #form-error {
    position: absolute;
    top: 10;
    left: 0;
    z-index: 99999;
    width: 100%;
  }
  </style>