<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-7" v-if="serverdata && serverdata.asset">
                <div> {{ serverdata.asset.name }} </div>
                <div> ({{ serverdata.asset.latitude }}, {{ serverdata.asset.longitude }}) </div>
                <table class="table">
                    <tr>
                        <th>Owner</th>
                        <td>{{ serverdata.asset.holdco }}</td>
                    </tr>
                    <tr>
                        <th>QSE</th>
                        <td>{{ serverdata.asset.qse }}</td>
                    </tr>
                    <tr>
                        <th>ISO</th>
                        <td>{{ serverdata.asset.iso }}</td>
                    </tr>
                    <tr>
                        <th>Node</th>
                        <td>{{ serverdata.asset.node }}</td>
                    </tr>
                    <tr>
                        <th>Capacity</th>
                        <td>{{ serverdata.asset.capacity }}</td>
                    </tr>
                    <tr>
                        <th>Duration</th>
                        <td>{{ serverdata.asset.duration }}</td>
                    </tr>
                    <tr>
                        <th>Completion</th>
                        <td>{{ serverdata.asset.date_of_completion }}</td>
                    </tr>
                </table>

            </div>

            <div class="col-12 col-md-5" style="min-height: 200px;">
                <AssetLocationChart id="location" :dataset="assetLocation" v-if="assetLocation"></AssetLocationChart>
            </div>
        </div>
        <div class="row mt-5 text-center" v-if="datasets && datasets.length > 0 && alldata && alldata.length > 0">
            <div class="col-12 col-md-6 col-xl-4 text-center" style="min-height: 200px;">
                <AssetsChart id="alldata" :dataset="alldata"></AssetsChart>
            </div>
            <div class="col-12 col-md-6 col-xl-4 text-center" v-for="(dataset, idx) in datasets" :key="idx"
                style="min-height: 200px;">
                <AssetConfigChart :id="'config_' + idx" :dataset="dataset">
                </AssetConfigChart>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AssetsChart from "@/components/assets/AssetsChart.vue";
import AssetConfigChart from "@/components/assets/AssetConfigChart.vue";
import AssetLocationChart from "@/components/assets/AssetLocationChart.vue";

export default {
    name: 'AssetDashboard',
    props: ["slug"],
    components: {
        AssetsChart,
        AssetConfigChart,
        AssetLocationChart
    },

    data() {
        return {
            datasets: [],
            assetLocation: null,
            configs: [],
            alldata: [],
        }
    },

    computed: {
        ...mapGetters(["serverdata"]),
    },

    async mounted() {
        console.log("AssetDashboard mounted", this.slug);
        console.log("serverdata", this.serverdata);
        this.assetLocation = [{
            "latitude": parseFloat(this.serverdata.asset.latitude),
            "longitude": parseFloat(this.serverdata.asset.longitude)
        }]
        console.log("assetLocation", this.assetLocation);

        this.configs = this.serverdata.configs;
        let getdataCalls = []
        this.configs.forEach((config) => {
            getdataCalls.push(this.getData(config));
        });
        let datasets = await Promise.all(getdataCalls);
        let alldata = [].concat.apply([], datasets)
        alldata = alldata.filter((d) => d.config_name != null);

        console.log("datasets =", this.datasets);
        this.alldata = alldata;
        this.datasets = datasets;
    },


    methods: {
        async getData(config) {
            let url = `/api/v1/config/${config.slug}/revenue/`;
            let response = await this.$axios.get(url);
            console.log("response api: ", response);
            if (response.data && response.data.length > 0) {
                let data = response.data.map(d => {
                    d["name"] = config.name
                    return d
                });
                return data;
            }
            return [];
        },


    }
}
</script>