<template>
    <C3Chart :chart="c3chart" :uuid="chartoptions.uuid || uuid" v-if="c3chart && c3chart.data && uuid">
    </C3Chart>
    <div class="w-100 h-100" v-else-if="chartoptions && chartoptions.chart_image">
        <img :src="'/static/' + chartoptions.chart_image" alt="chart" class="w-100 h-100" style="width=100%; height=100%" />
    </div>
</template>
    
<script>
import C3Chart from "./C3Chart.vue";
import moment from "moment";

export default {
    name: "CategoryChart",
    components: {
        C3Chart,
    },
    props: ["chartoptions", "chartdata"],

    data() {
        return {
            // options: {
            //     uuid: "xyz123",
            //     x: "date",
            //     showX: true,
            //     value: [],
            //     names: [],
            //     types: null,
            //     type: null,
            //     groups: [],
            //     ylabels: [],
            //     chart_image: null,
            //     aggregation: null,
            //     units: null,
            // },
            // data: [],
            uuid: "timeseries_xyz123",
        };
    },
    computed: {


        c3chart() {
            if (!this.chartoptions || !this.chartdata) {
                return null;
            }

            let cd = {
                data: {
                    json: this.chartdata,
                    keys: {
                        x: this.chartoptions.x,
                        value: this.chartoptions.value
                    },
                    xFormat: this.chartoptions.xFormat,
                    names: this.chartoptions.names,
                    types: this.chartoptions.types ? this.chartoptions.types : [],
                    type: this.chartoptions.type ? this.chartoptions.type : null,
                },
                line: {
                    step: {
                        type: "step-before",
                    },
                },
                bar: {
                    width: {
                        ratio: 0.75,
                    },
                },
                axis: {
                    x: {
                        type: "timeseries",
                        localtime: false,
                        tick: {
                            centered: true,
                            format: (d) => {
                                return this.formatDate(d, this.chartoptions.aggregation);
                            },
                            fit: true,
                        },
                        show: this.chartoptions.showX,
                    },
                    y: {
                        min: this.chartoptions.ylabels[0],
                        max: this.chartoptions.ylabels[
                            this.chartoptions.ylabels.length - 1
                        ],
                        tick: {
                            values: this.chartoptions.ylabels,
                            format: (d) => { return this.$filters.humanize(d) }
                        },
                        label: {
                            text: this.chartoptions.ytext,
                            position: "outer-middle",
                        },
                    },
                },
                grid: {
                    y: {
                        show: false,
                    },
                },

                regions: this.formatRegions(),
                tooltip: {
                    grouped: false,
                    format: {
                        title: (d) => {
                            return this.formatDate(d, this.chartoptions.aggregation);
                        },
                        value: (value) => {
                            // console.log("value in tooltip: ", value);
                            return value;
                        },
                    },
                },
                legend: {
                    show: this.chartoptions.showLegend,
                },
            };

            if (this.chartoptions.groups && this.chartoptions.groups.length > 0) {
                cd.data.groups = [this.chartoptions.groups];
            }

            if (this.chartoptions.tooltip) {
                cd.tooltip = this.chartoptions.tooltip;
            }

            if (this.chartoptions.colors) {
                cd.data.colors = this.chartoptions.colors;
            }


            console.log("c3chart cd = ", cd);
            return cd;
        },
    },

    async mounted() {
        console.log("Timeseries Chart mounted", this.chartoptions, this.chartdata);
    },



    methods: {

        formatDate(d, aggregation) {
            if (aggregation == "H")
                return moment(d).format("HH");
            if (aggregation == "M")
                return moment(d).format("MMM YYYY");
            else if (aggregation == "Y")
                return moment(d).format("YYYY");
            else if (aggregation == "D")
                return moment(d).format("YYYY-MM-DD");

            return moment(d).format("MMM YYYY");
        },

        formatRegions() {
            if (!this.chartoptions.regions) return [];

            let toggle = false;
            let regions = this.chartoptions.regions.map((el) => {
                let r = {
                    start: moment(el.start_date).format("YYYY-MM-DD"),
                    end: moment(el.end_date).format("YYYY-MM-DD"),
                    axis: "x",
                    class: toggle ? "regionBackcast1" : "regionBackcast2",
                };
                toggle = !toggle;
                return r;
            });
            return regions;
        },
    },
};
</script>
    
<style></style>