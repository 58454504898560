<template>
    <BasePlot :id="id" :plot="plot" v-if="plot" class="w-100 h-100"></BasePlot>
</template>

<script>
import BasePlot from "@/components/assets/BasePlot.vue";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";

export default {
    name: 'AssetsChart',
    props: ["id", "dataset"],
    components: {
        BasePlot
    },

    data() {
        return {
            plot: null,
            formatYear: d3.utcFormat("%Y"),
            formatMonth: d3.utcFormat("%Y-%b"),
            formatHour: d3.utcFormat("%H"),
            parseDate: d3.utcParse("%Y-%m-%d"),
            parseDateTime: d3.utcParse("%Y-%m-%d %H:%M:%S"),
            formatNumber: d3.format("~s"),
        }
    },


    async mounted() {
        console.log("AssetsChart mounted");

        this.plot = this.plotLine(this.dataset);
        console.log(this.plot);
    },


    methods: {

        plotLine(dataset) {

            console.log("dataset =", dataset);
            let filteredDataset = dataset.filter((value, idx) => idx % 3 == 0);
            console.log("filteredDataset =", filteredDataset);

            const plot = Plot.plot({
                caption: "All Configurations",
                margin: 60,
                marginBottom: 100,
                inset: 10,
                type: "utc",
                marks: [
                    // Plot.ruleY([0]),
                    Plot.lineY(dataset, {
                        x: (d) => this.parseDate(d.fordate), y: (d) => parseFloat(d.total),
                        // sort: "fordate",
                        stroke: "config_name",
                        curve: "step",
                        tip: true,
                        label: "Date",
                        interval: "1 day",
                        color: { legend: true, width: 640 },
                    }),
                    Plot.ruleX(dataset, Plot.pointerX({ x: (d) => this.parseDate(d.fordate), py: (d) => parseFloat(d.total), stroke: "grey", opacity: 0.7 })),
                    // Plot.gridX({ interval: "7 days" }),
                    Plot.text(["All Configurations"], { dy: -20, lineWidth: 30, frameAnchor: "top" }),

                ],
                y: {
                    // type: "linear",
                    // doamin: [2000, 3000],
                    grid: false,
                    label: "Revenue ($)",
                    format: "$.2f",
                    tickFormat: (d) => this.formatNumber(d),
                },
                x: {
                    // label: "Date",
                    grid: true,
                    interval: "1 day",
                    tickRotate: -90,
                    tickFormat: "%Y-%m-%d",
                    // ticks: dataset.filter((_, idx) => idx % 7 == 0).map((d) => this.parseDate(d.fordate)),
                },
            });
            return plot;
        }
    }
}
</script>