import { createApp } from 'vue'
import App from './App.vue'

import { createRouter, createWebHistory } from "vue-router";
import store from "./store.js";
//axios api wrapper
import axios from "./api.js";
import routes from "./routes.js";

import filters from "@/filters.js"
import tooltip from "./directives/tooltip.js";
import "@/assets/css/tooltip.css";
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

let app = createApp(App)
app.config.globalProperties.$axios = axios;

app.config.globalProperties.$filters = filters;

app.use(router)
app.use(store)
app.mount('#vue_container')

app.directive('tooltip', tooltip);

window.addEventListener('error', (event) => {
    console.log(event)
});

