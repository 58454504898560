<template>
    <C3Chart :chart="c3chart" :uuid="uuid" v-if="c3chart && c3chart.data && uuid"></C3Chart>
    <div class="w-100 h-100" v-else-if="chartoptions && chartoptions.chart_image">
        <img :src="'/static/' + chartoptions.chart_image" alt="chart" class="w-100 h-100" style="width=100%; height=100%" />
    </div>
</template>
    
<script>
import C3Chart from "./C3Chart.vue";
import moment from "moment";

export default {
    name: "CategoryChart",
    components: {
        C3Chart,
    },
    props: ["chartoptions", "chartdata"],

    data() {
        return {
            uuid: "category_xyz123"
        };
    },
    computed: {


        c3chart() {
            if (!this.chartoptions || !this.chartdata) {
                return null;
            }

            let cd = {
                data: {
                    json: this.chartdata,
                    keys: {
                        value: this.chartoptions.value
                    },
                    names: this.chartoptions.names,
                    type: this.chartoptions.type,
                },
                line: {
                    step: {
                        type: "step-after",
                    },
                },
                bar: {
                    width: {
                        ratio: 0.75,
                    },
                },
                axis: {
                    x: {
                        type: "category",
                        tick: {
                            centered: true,
                            multiline: false,
                            format: (d) => {
                                return this.chartdata[d][this.chartoptions.x]
                            },
                        },
                        show: this.chartoptions.showX,
                    },
                    y: {
                        min: this.chartoptions.ylabels[0],
                        max: this.chartoptions.ylabels[
                            this.chartoptions.ylabels.length - 1
                        ],
                        tick: {
                            values: this.chartoptions.ylabels,
                            format: (d) => { return this.$filters.humanize(d) }
                        },
                        label: {
                            text: this.chartoptions.ytext,
                            position: "outer-middle",
                        },
                    },
                },
                grid: {
                    y: {
                        show: false,
                    },
                },

                tooltip: {
                    grouped: false,
                    format: {
                        title: (d) => {
                            if (this.chartoptions.x == "date")
                                return this.formatDate(this.chartdata[d][this.chartoptions.x], this.chartoptions.aggregation);
                            else if (typeof (this.chartdata[d][this.chartoptions.x]) == "number")
                                return this.$filters.humanize(this.chartdata[d][this.chartoptions.x]);
                            else
                                return this.chartdata[d][this.chartoptions.x];
                        },
                        value: (value) => {
                            return value;
                        },
                    },
                },
                legend: {
                    show: this.chartoptions.showLegend,
                },
            };

            if (this.chartoptions.groups && this.chartoptions.groups.length > 0) {
                cd.data.groups = [this.chartoptions.groups];
            }
            if (this.chartoptions.types && this.chartoptions.types.length > 0) {
                cd.data.types = this.chartoptions.types;
            }

            // if (this.chartoptions.x == "scenario") {
            //     cd.axis.x.show = false;
            // }

            console.log("c3chart = ", cd);
            return cd;
        },
    },

    async mounted() {
        console.log("Category Chart mounted", this.chartoptions, this.chartdata)
    },

    methods: {
        formatDate(d, aggregation) {
            if (aggregation == "M")
                return moment(d).format("MMM YYYY");
            else if (aggregation == "Y")
                return moment(d).format("YYYY");
            else if (aggregation == "D")
                return moment(d).format("YYYY-MM-DD");

            return moment(d).format("MMM YYYY");
        },
    },
};
</script>
    
<style></style>