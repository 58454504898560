<template>
  <div class="w-100 h-100">
    <div :ref="uuid" :id="uuid" :class="uuid"></div>
  </div>
  <span id="ruler"></span>
</template>

<script>
import * as c3 from "c3";
import * as d3 from "d3";
import { merge } from "lodash";

export default {
  name: "C3Chart",
  props: {
    uuid: {
      type: String,
      required: false,
    },
    chart: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      c3chart: null,
      windowWidth: window.innerWidth,
      rotate: false,
      max_culling: 12,
    };
  },

  mounted() {
    console.log("c3chart mounted", this.uuid, this.chart);
    if (!this.isEmpty(this.chart)) {
      this.renderChart(this.chart);
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  watch: {
    chart: {
      handler: function () {
        this.updateChart();
      },
      deep: true,
    },
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    getChartDefaults() {
      let chartDefaults = {
        title: {
          show: false,
          text: "",
          position: "top-left",
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 300,
          },
        },
        // color: {
        //   pattern: this.getColorPatterns(),
        // },
        legend: {
          show: false,
          position: "inset",
          inset: {
            anchor: "top-right",
            x: 20,
            y: -40,
            step: 1,
          },
        },
        padding: {
          top: 40,
          right: 40,
          bottom: 40,
          left: 80,
        },
        size: {
          height: this.getChartHeight(),
          width: this.getChartWidth(),
        },
        point: {
          show: false,
        },
        grid: {
          y: {
            show: true,
          },
        },
        zoom: {
          enabled: false,
        },
        subchart: {
          show: false,
        },
        data: {
          order: null,
        },
        regions: [],
        axis: {
          x: {
            tick: {
              outer: true,
              rotate: this.rotate,
              culling: {
                max: this.max_culling,
              },
            },
          },
          y: {
            tick: {
              outer: false,
            },
          },
        },
      };
      // console.log("defaults", chartDefaults);
      return chartDefaults;
    },

    getColorPatterns() {
      let scheme = d3.scaleOrdinal(d3.schemeTableau10);
      let colors = scheme.range();
      console.log("getColorPatterns", scheme.range());
      // return [
      //   "#1f77b4",
      //   "#ff7f0e",
      //   "#aec7e8",
      //   "#ffbb78",
      //   "#2ca02c",
      //   "#98df8a",
      //   "#d62728",
      //   "#ff9896",
      //   "#9467bd",
      //   "#c5b0d5",
      //   "#8c564b",
      //   "#c49c94",
      //   "#e377c2",
      //   "#f7b6d2",
      //   "#7f7f7f",
      //   "#c7c7c7",
      //   "#bcbd22",
      //   "#dbdb8d",
      //   "#17becf",
      //   "#9edae5",
      // ];
      return colors;
    },

    isEmpty(obj) {
      console.log("isEmpty", obj);
      if (obj === null) return true;
      return Object.keys(obj).length === 0;
    },

    renderChart(chart) {
      let c3object = {
        bindto: this.$refs[this.uuid],
      };
      let defaults = this.getChartDefaults();
      c3object = merge(c3object, defaults);

      console.log("default_keys merged", c3object);

      c3object = merge(c3object, chart);

      if (!c3object.data.colors) {
        c3object.color = this.getColorPatterns();
      }

      this.setForSmallDevice(c3object);

      console.log("renderChart ---- c3chart object=", c3object);

      let k = Object.keys(c3object.data.json[0])[0]
      let xtype = typeof c3object.data.json[0][k];
      console.log("xtype", xtype);

      let xlabel_length = c3object.data.json.reduce((sum, item) => {
        let s = item[k].toString();
        try {
          if (c3object.axis.x.tick.format) {
            s = c3object.axis.x.tick.format(item[k]).toString();
            // console.log("s", s, this.visual_length(s));
          }
        } catch (e) {
          console.log("axis.x.tick.format not found");
        }
        return sum + this.visual_length(s);
      }, 0);
      let avg_label_length = xlabel_length / c3object.data.json.length;
      console.log("xlabel_length, typeof xtype, 20label length ==>", xlabel_length, xtype, avg_label_length * 20);

      console.log("this.getChartWidth()", this.getChartWidth());

      console.log("c3object", c3object.axis.x.tick.format);

      if (avg_label_length * 15 < (this.getChartWidth()) || xtype == "number") {
        c3object.axis.x.tick.rotate = false;
      } else {
        c3object.axis.x.tick.rotate = -90;
      }

      this.c3chart = c3.generate(c3object);

      //adjust the ylabel position to create some space 
      d3.select(".c3-axis-y-label").attr("transform", "translate(-10,0) rotate(-90)")

      this.c3chart.resize({
        height: this.getChartHeight(),
        width: this.getChartWidth(),
      });
    },

    visual_length(s) {
      var ruler = document.getElementById("ruler");
      ruler.innerHTML = s;
      return ruler.offsetWidth;
    },

    setForSmallDevice(c) {
      console.log("windowWidth:", this.windowWidth);
      if (this.windowWidth < 768) {
        console.log("chart in small device", c);
        c.axis.y.label.text = "";
        c.legend.show = false;
        c.padding.top = 10;
        c.padding.right = 10;
        c.padding.bottom = -20;
        c.padding.left = 10;
        c.onrendered = () => {
          d3.selectAll(".c3-axis.c3-axis-y").style("display", "none");
          d3.selectAll(".c3-axis.c3-axis-x").style("display", "none");
        };
      }
    },

    updateChart() {
      // console.log("flushing chart: ");
      // this.c3chart.regions([]);
      // this.c3chart.flush();
      // this.c3chart.unload(this.chart);
      // this.c3chart.load(this.chart);
      // this.c3chart.regions(this.chart.regions);
      console.log("updateChart ---- c3chart =");
    },

    getChartHeight() {
      let el = document.getElementById("chart-container");
      let height = el.scrollHeight;
      return height;
    },
    getChartWidth() {
      let el = document.getElementById("chart-container");
      let width = el.scrollWidth;
      return width;
    },
  },
};
</script>


<style src="../../node_modules/c3/c3.css"></style>
<style>
path.domain {
  display: none;
}

.c3-title {
  font-size: 16px !important;
}

.c3-region.regionBackcast1 {
  fill: #ffffff;
}

.c3-region.regionBackcast2 {
  fill: #999999;
}


.c3-axis-y,
.c3-axis-x text {
  font-size: 12px !important;
  font-family: "Roboto", sans-serif;
}

.c3-tooltip * {
  font-size: 12px !important;
}

.c3-legend-item * {
  font-size: 16px !important;
}

.c3-region text {
  color: black;
}

#ruler {
  visibility: hidden;
  white-space: nowrap;
}
</style>