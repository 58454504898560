import { createStore } from "vuex";
// import VuexPersistence from 'vuex-persist'

// const vuexLocal = new VuexPersistence({
//     storage: window.localStorage
// })


const store = createStore({
    // plugins: [vuexLocal.plugin],

    state() {
        return {
            serverdata: null,
            showError: false,
            showLoader: false,
            LoaderCount: 0,
            searchText: "",
        }
    },
    getters: {
        serverdata(state) {
            return state.serverdata;
        },

        showError(state) {
            return state.showError;
        },

        isLoading(state) {
            return state.showLoader;
        },

        getSearchText(state) {
            return state.searchText;
        },

    },
    mutations: {
        loadDjangoData(state, data) {
            if (data) state.serverdata = JSON.parse(data);
        },
        systemError(state) {
            state.showError = true;
        },
        resetError(state) {
            state.showError = false;
        },
        showLoader(state) {
            state.showLoader = true;
            state.LoaderCount++;
        },
        stopLoader(state) {
            state.LoaderCount--;
            if (state.LoaderCount == 0)
                state.showLoader = false;
        },
        clearData(state) {
            state.data = null
        },
        setSearchText(state, text) {
            state.searchText = text;
        },

    }
});

export default store;