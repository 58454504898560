// import BOSIndexChart from "@/components/BOSIndexChart"
import MultiChart from "@/components/MultiChart"
import DispatchChart from "@/components/DispatchChart"
import AssetDashboard from "@/components/assets/AssetDashboard"
import IsoIndex from "@/components/bosindex/IsoIndex"


const routes = [

    {
        path: '/tdata/:txn_data_slug', name: "SinglePointChart", components: {
            default: MultiChart,
        },
    },

    // {
    //     path: '/bosindex/:iso/', name: "BOSIndexChartDefault", components: {
    //         default: BOSIndexChart,
    //     },
    // },
    // {
    //     path: '/bosindex/:iso/:index_type', name: "BOSIndexChart", components: {
    //         default: BOSIndexChart,
    //     },
    // },
    {
        path: '/transaction/:txn_slug/multichart', name: "MultiChart", components: {
            default: MultiChart
        },
    },
    {
        path: '/transaction/:txn_slug/details', name: "SummaryChart", components: {
            default: MultiChart,
        },
    },
    {
        path: '/transaction/:txn_slug/dispatch', name: "DispatchChart", components: {
            default: DispatchChart,
        },
    },
    {
        path: '/assets/detail/:slug', name: "AssetDashboard", components: {
            default: AssetDashboard
        },
        props: true
    },
    {
        path: '/bosindex/:iso', name: "IsoIndex", components: {
            default: IsoIndex
        },
        props: true
    }
];

export default routes;
