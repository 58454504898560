<template>
  <loading
    :active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <system-error :show="showError" @close="resetError"></system-error>
  <div class="w-100 h-100"><router-view :key="$route.fullPath" /></div>
</template>

<script>
// Import overlay loader
import Loading from "vue3-loading-overlay";

import { mapMutations, mapGetters } from "vuex";
import SystemError from "./components/SystemError.vue";
export default {
  name: "App",
  components: { Loading, SystemError },

  data() {
    return {
      serverdata: null,
    };
  },

  computed: {
    ...mapGetters(["isLoading", "showError"]),
  },

  mounted() {
    console.log("app.vue mounted");
    this.serverdata = document.getElementById("data");
    if (this.serverdata) this.serverdata = this.serverdata.text;
    console.log("data =", this.serverdata);
    this.loadDjangoData(this.serverdata);
  },

  methods: {
    ...mapMutations(["loadDjangoData", "resetError"]),
  },
};
</script>

<style>
@import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

/* #vue_container {
  height: calc(100vh - 56px);
} */

.full-height {
  min-height: calc(100vh - 56px) !important;
}

/* .col-sidebar {
  width: 33%;
  min-width: 220px;
  max-width: 300px !important;
} */
</style>



