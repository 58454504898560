<template>
    <BasePlot :id="id" :plot="plot" v-if="plot" class="w-100 h-100"></BasePlot>
</template>

<script>
import BasePlot from "@/components/assets/BasePlot.vue";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";

export default {
    name: 'AssetConfigChart',
    props: ["id", "dataset"],
    components: {
        BasePlot
    },

    data() {
        return {
            plot: null,
            formatYear: d3.utcFormat("%Y"),
            formatMonth: d3.utcFormat("%Y-%b"),
            formatHour: d3.utcFormat("%H"),
            parseDate: d3.utcParse("%Y-%m-%d"),
            parseDateTime: d3.utcParse("%Y-%m-%d %H:%M:%S"),
            formatNumber: d3.format("~s"),
        }
    },


    async mounted() {
        console.log("AssetsConfigChart mounted", this.dataset);

        this.plot = this.plotStackedBar(this.dataset);
        console.log(this.plot);
    },


    methods: {


        convertDataset(dataset) {
            let newdataset = []
            dataset.forEach(d => {
                let obj = {}
                for (let key in d) {
                    if (key == "fordate") {
                        obj[key] = d[key]
                        continue
                    } else if (key != "total" && key != "config_name" && key != "name" && key != "reg") {
                        obj["curve"] = key
                        obj["value"] = parseFloat(d[key])
                    } else continue
                    newdataset.push(JSON.parse(JSON.stringify(obj)))
                }
            });
            console.log(newdataset)
            return newdataset;
        },

        plotStackedBar(data) {
            let dataset = this.convertDataset(data)

            let showCurve = false;
            let allempty = dataset.filter(d => d.value > 0);
            if (allempty.length > 0) {
                showCurve = true
            }

            const plot = Plot.plot({
                // aspectRatio: 9 / 16,
                caption: `${data[0].config_name}`,
                margin: 60,
                marginBottom: 100,
                inset: 10,
                type: "utc",
                // color: { legend: true, width: 640 },
                marks: [
                    Plot.ruleY([0]),
                    Plot.barY(dataset,
                        {
                            x: (d) => this.parseDate(d.fordate),
                            y: (d) => parseFloat(d.value),
                            fill: showCurve ? "curve" : null,
                            rx: 2,
                            inset: 0.2,
                            tip: true,
                        }),
                    Plot.ruleX(dataset, Plot.pointerX({ x: (d) => this.parseDate(d.fordate), stroke: "grey", opacity: 0.7 })),
                    // Plot.gridX(data.filter((_, idx) => idx % 4 == 0).map((d) => this.parseDate(d.fordate))),
                    // showCurve ? Plot.text([data[0].config_name], { dy: -20, lineWidth: 30, frameAnchor: "top" }) : null,
                    // Plot.frame({ top: 30, bottom: 30, left: 30, right: 30, stroke: "grey" })
                ],
                y: {
                    grid: false,
                    label: "Revenue ($)",
                    tickFormat: (d) => this.formatNumber(d),
                },
                x: {
                    // label: "Day",
                    // labelAnchor: "right",
                    grid: true,
                    interval: "1 day",
                    tickRotate: -90,
                    tickFormat: "%Y-%m-%d",
                    // ticks: data.filter((_, idx) => idx % 7 == 0).map((d) => this.parseDate(d.fordate)),
                },
            });
            return plot;
        },

    }
}
</script>