<template>
    <div class="w-100 h-100">
        <div :id="id" v-if="id"></div>
    </div>
</template>

<script>
export default {
    name: 'BasePlot',

    props: ["id", "plot"],

    mounted() {
        console.log("Plot mounted, id: ", this.id);
        document.querySelector('#' + this.id).append(this.plot);

    }
}
</script>

<style></style>