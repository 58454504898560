<template>
    <div class="row" v-if="summary_indices && summary_indices.length > 0">
        <div class="col-12 col-md-6 col-xl-4" :class="index.order_classes" v-for="index in summary_indices"
            :key="index.slug">
            <IsoIndexChart :id="index.slug" :data="index" class="p-2">
            </IsoIndexChart>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import IsoIndexChart from "@/components/bosindex/IsoIndexChart.vue";

export default {
    name: 'IsoIndex',
    props: ["iso"],
    components: {
        IsoIndexChart
    },

    data() {
        return {
            summary_indices: [],
        }
    },

    computed: {
        ...mapGetters(["serverdata"]),
    },

    async mounted() {
        this.summary_indices = [];
        console.log("IsoIndex mounted", this.iso);
        console.log("serverdata", this.serverdata);
        let summary_indices = JSON.parse(JSON.stringify(this.serverdata.indices));

        let getdataCalls = []
        summary_indices.forEach((index) => {
            getdataCalls.push(this.getData(index));
        });
        await Promise.all(getdataCalls);

        let mins = [];
        let maxs = [];
        summary_indices.forEach((index) => {
            mins.push(Math.min(...index.data.map(item => item.monthly)));
            maxs.push(Math.max(...index.data.map(item => item.monthly)));
        });
        let min = Math.min(...mins);
        let max = Math.max(...maxs);

        this.summary_indices = summary_indices.map((index) => {
            index["min"] = min;
            index["max"] = max;
            return index;
        });
        console.log("summary_indices", this.summary_indices)

    },


    methods: {
        async getData(index) {
            let url = `/api/v1/bosindex/${index.slug}/summary/`;
            let response = await this.$axios.get(url);
            console.log("response api: ", response);
            index["data"] = response.data;
        },


    }
}
</script>