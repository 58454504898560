<template>
    <BasePlot :id="id" :plot="plot" v-if="plot" class="w-100 h-100"></BasePlot>
</template>

<script>
import BasePlot from "@/components/assets/BasePlot.vue";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";

export default {
    name: 'IsoIndexChart',
    props: ["id", "data"],
    components: {
        BasePlot
    },

    data() {
        return {
            plot: null,
            formatYear: d3.utcFormat("%Y"),
            formatMonth: d3.utcFormat("%Y-%b"),
            formatHour: d3.utcFormat("%H"),
            parseDate: d3.utcParse("%Y-%m-%d"),
            parseDateTime: d3.utcParse("%Y-%m-%d %H:%M:%S"),
            formatNumber: d3.format("~s"),
        }
    },


    async mounted() {
        console.log("IsoIndexChart mounted", this.id, this.data);

        this.plot = this.bosindex(this.data);
        console.log(this.plot);
    },


    methods: {


        bosindex(index) {
            let showBar = false;

            let dataset = index.data;
            let allempty = dataset.filter(d => d.monthly > 0);
            if (allempty.length > 0) {
                showBar = true;
            }
            console.log("bosindex showbar: ", showBar)

            console.log("bosindex dataset = ", dataset);

            const plot = Plot.plot({
                caption: `${index.name}`,
                margin: 50,
                marginBottom: 100,
                inset: 10,
                marks: [
                    Plot.ruleY([0]),
                    Plot.rectY(dataset,
                        {
                            x: (d) => this.parseDate(d.date),
                            y: (d) => parseFloat(d.monthly),
                            rx: 3,
                            fill: showBar ? "lightblue" : null,
                            tip: true,
                            interval: "month",
                            title: (d) => [`${this.formatMonth(this.parseDate(d.date))} : ${d.monthly ? d.monthly.toFixed(2) : null}`,
                            `Annual: ${d.annual ? d.annual.toFixed(2) : null}`,
                            ].join("\n"),
                        }),
                    Plot.lineY(dataset,
                        {
                            x: (d) => this.parseDate(d.date),
                            y: (d) => parseFloat(d.annual),
                            stroke: "black",
                            strokeWidth: 2,
                            curve: "step",
                        }),
                    // Plot.gridX({ interval: 5 })
                ],
                y: {
                    domain: [index.min, index.max],
                    grid: false,
                    label: "Revenue ($/kwm)",
                    format: "$.2f",
                },
                x: {
                    // domain: [this.parseDate("2017-01-01"), this.parseDate("2023-06-30")],
                    label: "Month",
                    labelAnchor: "right",
                    interval: "1 month",
                    tickFormat: "%Y-%b",
                    tickRotate: -90,
                    // ticks: 4,
                    ticks: dataset.map(d => this.parseDate(d.date)).filter(d => d.getUTCMonth() % 3 == 0),

                },
            });
            return plot;
        }

    }
}
</script>