<template>
  <TimeseriesChart :chartoptions="chartOptions" :chartdata="chartData" v-if="chartOptions && chartOptions.timeseries"
    :key="refresh_timeseries">
  </TimeseriesChart>
  <!-- <CategoryChart :chartoptions="chartOptions" :chartdata="chartData" v-else :key="refresh_category"></CategoryChart> -->
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";
// import CategoryChart from "./CategoryChart.vue";
import TimeseriesChart from "./TimeseriesChart.vue";
import moment from "moment";
import * as d3 from "d3";

export default {
  name: "MultiChart",
  components: {
    TimeseriesChart
  },
  props: ["txn_slug"],

  data() {
    return {
      chartData: null,
      chartOptions: null,
      chartMetaData: {},
      charttypes: {
        linechrt: "line",
        barchart: "bar",
        groupbar: "bar",
        stackbar: "bar",
        histogram: "bar",
        radrplot: "line",
      },
      refresh_category: 1,
      refresh_timeseries: 1,

    };
  },
  computed: {
    ...mapGetters(["serverdata"]),

  },

  async mounted() {
    console.log("DispatchChart mounted", this.serverdata);
    this.chartData = null;
    this.chartOptions = null;
    if (this.serverdata) {
      try {
        this.showLoader();
        let response = await this.loadAPI(this.serverdata);
        console.log("response from api: ", response);
        if (response.data && response.data.data) {
          let chart = this.setChart(response.data);
          this.chartOptions = chart.chartoptions;
          this.chartData = chart.chartdata;
        } else {
          this.chartOptions = {}
          this.chartOptions.chart_image = this.serverdata.chart_image
        }
      } finally {
        this.stopLoader();
      }
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    ...mapMutations(["showLoader", "stopLoader", "systemError"]),

    onResize() {
      this.refresh_category = !this.refresh_category;
      this.refresh_timeseries = !this.refresh_timeseries;
    },

    async loadAPI(data) {
      let url = "/api/v1/stack/transaction/" + data.transaction_slug + "/dispatch/"
      let response = await this.$axios.post(url, data);
      return response;
    },

    setChart(data) {
      console.log("set options and data for the chart", data);

      let chartoptions = {}
      let chartdata = []

      //set data
      chartdata = data.data.map(el => {
        for (let key in el) {
          if (typeof (el[key]) == 'number' && !Number.isInteger(el["key"])) {
            el[key] = parseFloat(el[key].toFixed(2))
          }
        }
        return el;
      });

      //set options
      chartoptions = {
        uuid: "dispatch",
        x: "date",
        showX: true,
        value: [],
        names: [],
        groups: [],
        ylabels: [],
        aggregation: null,
        chart_image: null,
      };
      // chartoptions.uuid = data.meta.slug;
      chartoptions.x = data.meta.x
      chartoptions.showX = true
      chartoptions.value =
        Object.keys(chartdata[0]).filter(
          (el) => el != data.meta.x
        );
      chartoptions.names =
        chartoptions.value.forEach((el) => {
          return chartoptions.names[el] = this.$filters.capitalize(el);
        });

      chartoptions.types = [];
      chartoptions.value.forEach((el) => {
        if (el == "soc") chartoptions.types[el] = "step"
        else chartoptions.types[el] = "bar";
      });
      console.log("chartoptions.types = ", chartoptions.types);

      // chartoptions.type = "bar";
      chartoptions.chart_image = this.serverdata.chart_image
      chartoptions.aggregation = data.meta.aggregation
      chartoptions.units = data.meta.units
      chartoptions.units_long_name = data.meta.units_long_name
      chartoptions["ytext"] = `${this.$filters.capitalize(
        data.meta.coltype
      )}-${this.$filters.capitalize(data.meta.subtype)}-${this.$filters.capitalize(
        data.meta.column
      )} (${chartoptions.units_long_name})`;


      chartoptions["regions"] = [];

      if (data.meta["charttype"] == "barchart") {
        chartoptions["groups"] = [
          ...Object.keys(chartdata[0]).filter((el) => el != chartoptions.x),
        ];
      } else {
        chartoptions["groups"] = [];
      }

      let result = {};
      if (chartoptions.groups && chartoptions.groups.length > 1) {
        result = this.$filters.getYMinMaxSigned(chartdata, [chartoptions.x, "soc"]);
        result["ymax"] = Math.max(result["ymax"], 1);
      } else {
        result = this.$filters.getYMinMax(chartdata, [chartoptions.x]);
      }
      console.log("ymax min, result", result);
      chartoptions.ylabels = this.setYlabels(result.ymin, result.ymax)

      if (data.meta.x == "date" && !data.meta.sort_order) {
        chartoptions.timeseries = true;
        chartoptions.xFormat = "%Y-%m-%d %H:%M:%S"
      } else {
        chartoptions.timeseries = false;
      }

      chartoptions.tooltip = {
        grouped: false,
        format: {
          title: (d) => {
            return moment(d).format("HH:mm")
          },
          value: (value, ratio, id, index) => {
            if (id.includes("offeredcapacity") && value != 0) {
              let newid = id.replace("offeredcapacity", "deployedcapacity");
              let newvalue = chartdata[index][newid];
              return parseFloat((value + newvalue).toFixed(2));
            }
            return parseFloat(value).toFixed(2);
          },
          name: (name) => {
            if (data.meta.is_reg) {
              if (name == "offeredcapacity::regdown") return "offeredcapacity::reg";
              else if (name == "offeredcapacity::regup") return "offeredcapacity::reg";
            }
            return name;
          }
        },
      };

      if (data.meta.is_reg) {
        let scheme = d3.scaleOrdinal(d3.schemeTableau10);
        let colors = scheme.range();


        chartoptions.colors = {};
        chartoptions.value.forEach((el, i) => {
          chartoptions.colors[el] = colors[i];
        });
        chartoptions.colors["offeredcapacity::regdown"] = chartoptions.colors["offeredcapacity::regup"];

        console.log(chartoptions.colors);

      }
      console.log("chart options set: ", chartoptions);
      console.log("chartData = ", chartdata);



      return { chartoptions, chartdata };
    },




    setYlabels(ymin, ymax) {
      let range = ymax - ymin;
      console.log("range = ", range);
      if (range == 0) {
        return [ymin, ymax];
      }
      let tic_range = range / 5;
      console.log("tic_range = ", tic_range);
      let rounded_tick_range = this.$filters.getClosestRoundedNumber(tic_range);
      console.log("rouned_tick_range = ", rounded_tick_range);

      let new_ymin = rounded_tick_range * Math.floor(ymin / rounded_tick_range);
      let new_ymax =
        rounded_tick_range * Math.round(1 + ymax / rounded_tick_range);
      console.log("new_ymin, new_ymax = ", new_ymin, new_ymax);

      let ylabels = [];
      let ylabel = new_ymin;
      while (ylabel <= new_ymax) {
        ylabels.push(
          parseFloat(ylabel.toFixed(2))
        );
        ylabel += rounded_tick_range;
      }

      console.log("ylabels = ", ylabels);
      return ylabels;
    },


  },
};
</script>
  
<style>
#dispatch .c3-line {
  stroke-width: 2px;
  stroke: green;
}
</style>


